import styled from "styled-components";

export const ButtonSecondary = ({
  as,
  href,
  className,
  onClick,
  target,
  rel,
  children,
  inverted,
  ...props
}) => {
  return (
    <Wrapper
      as={as}
      href={href}
      className={className}
      onClick={onClick}
      target={target}
      rel={rel}
      inverted={inverted}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  background-color: #ffffff;
  color: #0731a1;
  border: none;
  outline: 0;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "BrandonBld";
  border-radius: 25px;
  cursor: pointer;
  white-space: nowrap !important;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  width: fit-content;
  padding: 0 25px;

  &:hover {
    background-color: #82dcff;
    color: #0731a1;
  }
  &:focus {
    background-color: #000;
    color: #fff;
    border: 3px solid #8297d0;
  }
`;
