import { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { ReactComponent as Chevron } from "Components/Assets/chevron.svg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

export const Header = ({ showHeader, registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const {
    brand: brandLogo,
    // event: eventLogo,
    // secondary: secondaryLogo,
  } = event.logos;

  let brandLogoImg = `${api}/${storageDirectory}/${brandLogo?.image}?alt=media`;

  const logoClickHandler = (link) => {
    logClick({
      label: "Header Logo",
      url: link,
    });
  };

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <>
      {showHeader && (
        <HeaderWrapper as="header">
          <HeaderContainer>
            <Link
              aria-label="UScellular Built for Us Link opens in a new window"
              href="https://www.uscellular.com/BuiltForUS"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                linkClickHandler(
                  "https://www.uscellular.com/BuiltForUS",
                  "UScellular Built for Us",
                  "header"
                )
              }
            >
              {brandLogoImg && (
                <PrimaryLogo
                  src={brandLogoImg}
                  alt="blue and red USCellular logo"
                />
              )}
            </Link>
            <SkipLink
              href="#main"
              aria-label="link to skip to main content"
              style={{ color: "#fff !important" }}
            >
              Skip to main content
            </SkipLink>
            <Link
              aria-label="Learn more about Life in us mode by US Cellular link opens in a new tab"
              href="https://www.uscellular.com/BuiltForUS"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                linkClickHandler(
                  "https://www.uscellular.com/BuiltForUS",
                  "UScellular Built for Us",
                  "header"
                )
              }
            >
              <p>What is US Mode?</p>
              <Chevron />
            </Link>
          </HeaderContainer>
        </HeaderWrapper>
      )}
    </>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: row;
  padding: 0 35px;
  width: 100%;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0 25px;
  }
`;

const HeaderContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 5px 0;
  position: relative;
  & > p {
    white-space: nowrap;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
  }
`;

const Link = styled.a`
  font-family: "BrandonBld" !important;
  color: #322ffd;
  text-decoration: none;
  z-index: 2;
  position: relative;

  p {
    display: inline;
    padding-right: 8px;
  }
  &:hover {
    svg {
      right: -16px;
    }
  }
  svg {
    position: absolute;
    right: -8px;
    bottom: 0;
    top: 0;
    margin-top: auto;
    margin-bottom: auto;
    display: inline;
    height: 12px;
  }
`;
const SkipLink = styled.a`
  color: #fff !important;
  position: absolute;
  top: 0;
  left: 20%;
  bottom: 0;
  z-index: 0;
  &:visited,
  &:active {
    color: #fff !important;
  }
`;

const Logo = styled.img`
  max-width: 120px;
  width: 100%;
  display: flex;
`;

const PrimaryLogo = styled(Logo)``;
