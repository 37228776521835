import { DateMask } from "Components/Form/InputMasks/DateMask";
import { FieldMessage } from "Components/Form/Fields";
import { useState } from "react";
import styled, { css } from "styled-components";

export const DateField = ({
  onChange,
  field,
  value,
  error = {},
  ageGate,
  ...props
}) => {
  const [inputValue, setInputValue] = useState();
  const handleText = (value) => {
    if (!!error.message) {
      value = "";
    }
    if (value) {
      let splitDate = value.split("/");
      if (
        splitDate[0]?.length !== 2 ||
        splitDate[1]?.length !== 2 ||
        splitDate[2]?.length !== 4 ||
        !splitDate[0]?.length ||
        !splitDate[1]?.length ||
        !splitDate[2]?.length
      ) {
        let newDate = value.replaceAll("/", "");
        if (newDate.length === 8) {
          let updatedValue = `${newDate[0]}${newDate[1]}/${newDate[2]}${newDate[3]}/${newDate[4]}${newDate[5]}${newDate[6]}${newDate[7]}`;
          value = updatedValue;
        }
      }
    }
    onChange({ [field.name]: value });
  };

  return (
    <>
      <DateMask
        date={value}
        value={inputValue}
        onChange={(value) => handleText(value)}
        error={!!error.status}
        placeholder={field.placeholder ?? "MM/DD/YYYY"}
        ageGate={ageGate}
        aria-label={field.label}
        name={field.label}
        id={field.name}
        {...props}
      />
      <Placeholder
        error={error}
        visible={false}
        htmlFor={props.label}
      ></Placeholder>
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};
const Placeholder = styled.label`
  display: flex;
  flex-direction: row;
  color: #fff;
  position: absolute;
  top: 12px;
  left: 12px;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  /* font-family: "AntennaRegular"; */

  ${(props) =>
    !props.visible &&
    css`
      /* top: -20px;
    left: 0;
    font-size: 12px;
     */
      display: none;
    `}
`;
