import React, { useContext, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { RingSpinner } from "Components/RingSpinner";
import { EventContext, AnalyticsContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
  ButtonSecondary,
} from "Components";
import { VideoPlayer } from "Components/Media/VideoPlayer";
import { RegistrationForm } from "Components/Form";
import { useMediaQuery } from "hooks";
import { useSmallMediaQuery } from "hooks/useSmallMediaQuery";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);
    const [focus, setFocus] = useState(false);
    const [loading, setLoading] = useState(false);

    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event.meta.storageDirectory;
    const formRef = useRef();
    const isMobile = useMediaQuery();
    const isSmallMobile = useSmallMediaQuery();
    // logo
    const { brand: brandLogo } = event.logos;
    let brandLogoImg = `${api}/${storageDirectory}/${brandLogo?.image}`;
    let logoFest = `${api}/${storageDirectory}/${brandLogo?.logoFest}`;

    const {
      heroBgPre,
      heroBgPreMob,
      heroBgPostReg,
      heroBgPostCamp,
      thumbHero,
      partPattern,
      builtForUs,
      chevron,
      contentBg1: img1,
      ChaseRice1,
      ChaseRice2,
      ChaseRice3,
      ChaseRice4,
      ChaseRice5,
      song,
      capTop,
      capBottom,
      patternSquare,
      thumbFestBg,
      thumbFest,
    } = event.images;
    let heroImgPre = `${api}/${storageDirectory}/${heroBgPre}`;
    let heroImgPreMob = `${api}/${storageDirectory}/${heroBgPreMob}`;
    let heroImgPostReg = `${api}/${storageDirectory}/${heroBgPostReg}`;
    let heroImgPostCamp = `${api}/${storageDirectory}/${heroBgPostCamp}`;
    let thumbHeroImg = `${api}/${storageDirectory}/${thumbHero}`;
    let thumbFestBgImg = `${api}/${storageDirectory}/${thumbFestBg}`;
    let thumbFestImg = `${api}/${storageDirectory}/${thumbFest}`;
    let partialPattern = `${api}/${storageDirectory}/${partPattern}`;
    let builtForUsImg = `${api}/${storageDirectory}/${builtForUs}`;
    let chevronImg = `${api}/${storageDirectory}/${chevron}`;
    let contentBg1 = `${api}/${storageDirectory}/${img1}`;
    let ChaseRiceImg1 = `${api}/${storageDirectory}/${ChaseRice1}`;
    let ChaseRiceImg2 = `${api}/${storageDirectory}/${ChaseRice2}`;
    let ChaseRiceImg3 = `${api}/${storageDirectory}/${ChaseRice3}`;
    let ChaseRiceImg4 = `${api}/${storageDirectory}/${ChaseRice4}`;
    let ChaseRiceImg5 = `${api}/${storageDirectory}/${ChaseRice5}`;
    let songImg = `${api}/${storageDirectory}/${song}`;
    let patternSquareImg = `${api}/${storageDirectory}/${patternSquare}`;
    let captionTop = `${api}/${storageDirectory}/${capTop}`;
    let captionBottom = `${api}/${storageDirectory}/${capBottom}`;

    const festVideoUrl = `https://storage.googleapis.com/whitelabel-set-live.appspot.com/709-usc-chase-rice/festmobile/manifest.m3u8`;
    const desktopVideoUrl = `https://storage.googleapis.com/whitelabel-set-live.appspot.com/709-usc-chase-rice/desktop/manifest.m3u8`;
    const heroVideoUrl = `https://storage.googleapis.com/whitelabel-set-live.appspot.com/709-usc-chase-rice/hero/manifest.m3u8`;
    const heroVideoMp4 = `https://storage.googleapis.com/whitelabel-set-live.appspot.com/709-usc-chase-rice/hero.mp4`;
    const songUrl =
      "https://www.youtube.com/embed/wkxI3u5Pvlo?autoplay=0&cc_load_policy=1";

    if (registered) formStep = event.register.sweeps.length - 1;
    // registration content
    const {
      heading,
      subHeading,
      body,
      formHeading,
      formLegal,
      submit,
    } = event.register.sweeps[formStep];

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event.callToActions.musicVideo;
    let videoBgDesktop = `${api}/${storageDirectory}/${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/${storageDirectory}/${musicVideoBg.mobile}?alt=media`;

    // artist cta
    const { heroVideoLink } = event.callToActions.artists;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event.callToActions.brand.background;
    let brandBgDesktop = `${api}/${storageDirectory}/${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/${storageDirectory}/${brandBgM}?alt=media`;

    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event.callToActions.brand.product;
    let productDesktop = `${api}/${storageDirectory}/${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/${storageDirectory}/${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      body: postCampaignBody,
    } = event.postCampaign;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnLinkPostCampaign: PostCampaignCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event.callToActions.brandSecondary;

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpen(bool);
    };
    const scrollToSection = (ref) => {
      window.scrollTo({ top: ref.current.offsetTop });
      setFocus(true);
    };

    if (loading) {
      window.scrollTo(0, 0);
      return <RingSpinner />;
    }

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showFooter
        showHeader
        postCampaign={postCampaign}
      >
        <Container postCampaign={postCampaign}>
          <SkipLink
            href="#footer"
            aria-label="link to skip to footer from main"
            style={{ color: "#fff !important" }}
          >
            Skip to footer content
          </SkipLink>
          <HeroContainer>
            {postCampaign ? (
              <>
                <HeroContent>
                  <Heading tabIndex={0}>
                    SWEEPSTAKES REGISTRATION IS CLOSED
                  </Heading>
                  <Body>
                    Still interested in seeing Chase Rice at Summerfest? Visit
                    Summerfest.com or click “Buy Tickets” below.
                  </Body>
                </HeroContent>
                <HeroPostBackground>
                  <img
                    src={heroImgPostCamp}
                    alt="Chase Rice smiling on stage"
                  />
                </HeroPostBackground>
              </>
            ) : registered ? (
              <>
                <HeroContent>
                  <Heading tabIndex={0}>REGISTRATION CONFIRMED</Heading>
                  <Body>
                    You’ve entered for a chance to win the Chase Rice VIP Fan
                    Experience at Summerfest. Good luck. 
                  </Body>
                </HeroContent>
                <HeroPostBackground>
                  <img
                    src={heroImgPostReg}
                    alt="Chase Rice performs on stage in front of a crowd"
                  />
                </HeroPostBackground>
              </>
            ) : (
              <>
                <HeroBackground
                  src={isSmallMobile ? heroImgPreMob : heroImgPre}
                  alt="Chase Rice singing into a microphone in front of a crowd"
                />
                <HeroRegContent>
                  <MainHeading tabIndex={0}>
                    Chase Rice <br />
                    VIP Fan <br />
                    Experience
                  </MainHeading>
                  <ButtonPrimary
                    tabIndex={0}
                    style={{ margin: "30px auto 10px 0" }}
                    onClick={() => {
                      scrollToSection(formRef);
                      linkClickHandler(
                        "Anchor to form",
                        "Button Anchor to form",
                        "internal"
                      );
                    }}
                    aria-label="Enter here Button to automatically scroll to fill up the form to win tickets at Summerfest"
                    role="content main"
                  >
                    Enter Here
                  </ButtonPrimary>
                </HeroRegContent>
              </>
            )}
          </HeroContainer>

          <VideoContainerHero layered={!registered && !postCampaign}>
            <VideoBlock
              onClick={() => setModalVisible(true, heroVideoMp4)}
              role="button"
              aria-label="Click on this video button to open the video full screen"
            >
              <VideoContent
                play
                path={heroVideoUrl}
                loop
                mute
                thumbnail={thumbHeroImg}
                isBackground
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  maxWidth: "100%",
                  maxHeight: "50%",
                  width: "100%",
                }}
              />
            </VideoBlock>
            <BlockContent>
              <Heading>Life in us mode</Heading>
              <Body>
                UScellular<span className="super">®</span> and Chase Rice have
                partnered to promote the power of unplugging to connect with
                what matters most. Discover how Chase Rice lives{" "}
                <i>Life in US Mode</i>, making every moment meaningful by
                balancing time on and off the screen to connect with friends,
                fans and community.
              </Body>
              <ButtonSecondary
                tabIndex={-1}
                style={{ marginTop: "35px" }}
                aria-label="button to watch Chase Rice's story"
                onClick={() => {
                  setModalVisible(true, heroVideoLink);
                  linkClickHandler(
                    "https://www.youtube.com/watch?v=z6hrWyGUwFI&embeds_referring_euri=http%3A%2F%2Flocalhost%3A3001%2F&source_ve_path=OTY3MTQ&feature=emb_imp_woyt",
                    "Watch Video",
                    "external"
                  );
                }}
              >
                Watch Now
              </ButtonSecondary>
            </BlockContent>
          </VideoContainerHero>

          {!registered && !postCampaign ? (
            <FormContainer>
              <FormWrapper>
                <Heading ref={formRef} tabIndex={0}>
                  Chase Rice VIP Experience at Summerfest
                </Heading>
                <Body tabIndex={0}>
                  Enter for your chance to win festival tickets, transportation,
                  hotel accommodations, and a meet and greet with Chase Rice at
                  Summerfest in Milwaukee, WI on July 6th.
                  <br />
                  <br />
                  Summerfest is a three-weekend music festival in Milwaukee, WI,
                  featuring hundreds of the biggest names in music.
                </Body>
                <RegistrationForm
                  formPart={formStep}
                  formHeading={formHeading}
                  formLegal={formLegal}
                  submitButton={submit}
                  setIneligible={setIneligible}
                  focus={focus}
                  setLoading={setLoading}
                />
              </FormWrapper>

              <VideoBlock id="summerfest" role="button">
                <VideoContent
                  play
                  path={festVideoUrl}
                  loop
                  mute
                  thumbnail={thumbFestImg}
                  isBackground
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    maxWidth: "100%",
                    maxHeight: "50%",
                    width: "100%",
                  }}
                />
                <div style={{ height: "100%" }}></div>
                {!isMobile && (
                  <div>
                    <PartPatternImg src={partialPattern} alt="" />
                  </div>
                )}
              </VideoBlock>
            </FormContainer>
          ) : (
            <>
              <VideoBlockPost>
                <VideoContent
                  play
                  path={desktopVideoUrl}
                  loop
                  mute
                  thumbnail={thumbFestBgImg}
                  isBackground
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "100%",
                  }}
                />
                <VideoBlockContent>
                  <Logo
                    src={logoFest}
                    id="logo"
                    alt="blue and red USCellular logo"
                  />
                  {postCampaign ? (
                    <>
                      <Heading tabIndex={0}>Visit summerfest</Heading>
                      <Body float>
                        You don’t have to miss out on going to Summerfest music
                        festival in Milwaukee, WI. Amazing performances and
                        unforgettable moments are just a click away.
                      </Body>
                      <ButtonPrimary
                        tabIndex={0}
                        as="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.summerfest.com/ticket-info/"
                        aria-label="Summerfest ticket info Link opens in a new window"
                      >
                        Buy Tickets
                      </ButtonPrimary>
                    </>
                  ) : (
                    <>
                      <Heading tabIndex={0}>
                        9 DAys, 600 Artists,
                        <br /> Milwaukee
                      </Heading>
                      <Body float>
                        Summerfest music festival in Milwaukee, WI, is a
                        cornerstone summer celebration that attracts the music
                        industry’s hottest stars, emerging talent, and local
                        favorites. Learn more about the lineup and available
                        ticket options for all three weekends.
                      </Body>

                      <ButtonPrimary
                        tabIndex={0}
                        as="a"
                        target="_blank"
                        role="link"
                        rel="noopener noreferrer"
                        href="https://www.summerfest.com/ticket-info/"
                        aria-label="Summerfest ticket info Link opens in a new window"
                        onClick={() =>
                          linkClickHandler(
                            "https://www.summerfest.com/ticket-info",
                            "Summerfest Buy Tickets",
                            "external"
                          )
                        }
                      >
                        Learn more
                      </ButtonPrimary>
                    </>
                  )}
                </VideoBlockContent>
              </VideoBlockPost>
            </>
          )}

          <ContentUSC>
            {isMobile && (
              <div>
                <PartPatternImg src={partialPattern} alt="" />
              </div>
            )}
            <ContainerPadding>
              <div>
                <img src={builtForUsImg} alt="" />
                <Body>
                  UScellular is built for genuine connections that bring us
                  together and back together again. By emphasizing meaningful
                  interactions over digital distractions, we’re bridging the gap
                  between staying connected and nurturing real-world
                  relationships. In other words, we’re built to make us feel a
                  little more like US again.
                </Body>

                <ButtonSecondary
                  tabIndex={0}
                  as="a"
                  target="_blank"
                  role="link"
                  rel="noopener noreferrer"
                  href="https://www.uscellular.com/BuiltforUS"
                  aria-label="UScellular Built for Us Link opens in a new window"
                  onClick={() =>
                    linkClickHandler(
                      "https://www.uscellular.com/BuiltForUS",
                      "UScellular Built for Us",
                      "external"
                    )
                  }
                >
                  Learn more
                </ButtonSecondary>
              </div>
            </ContainerPadding>
            <ContainerScroll>
              <SubHeading tabIndex={0}>
                Continue Scrolling for Exclusive Chase Rice Content
              </SubHeading>
              <img src={chevronImg} alt="" />
            </ContainerScroll>
            <ContainerUsMode
              bg={contentBg1}
              alt="Chase Rice standing in a field holding a bow and arrow"
            >
              <Content id="CTA">
                <Logo src={brandLogoImg} alt="blue and red USCellular logo" />
                <Heading tabIndex={0}>What is US Mode?</Heading>
                <Body>
                  Endless notifications can make it hard to focus. That’s why
                  UScellular introduced US Mode, a setting on your device that
                  helps you regain control of your “connectedness”. US Mode
                  allows you to personalize when and how your phone sends alerts
                  so you can minimize distractions and focus on human
                  connections.
                </Body>
                <ButtonPrimary
                  tabIndex={0}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.uscellular.com/BuiltForUS"
                  role="link"
                  aria-label="UScellular Built for Us Link opens in a new window"
                  onClick={() =>
                    linkClickHandler(
                      "https://www.uscellular.com/BuiltForUS",
                      "UScellular Built for Us",
                      "external"
                    )
                  }
                >
                  Learn more
                </ButtonPrimary>
              </Content>
              {isMobile && (
                <img
                  id="mobileBgImg"
                  src={contentBg1}
                  alt="Chase Rice playing shuffle board with friends at a bar"
                />
              )}
            </ContainerUsMode>
          </ContentUSC>
          {/* <SnapWrapper> */}
          <ContainerUsMode>
            <img src={partialPattern} alt="" id="rotate" />
            <ContentInfo>
              <Content>
                <Heading tabIndex={0}>OFF-SCREEN CONNECTIONS</Heading>
                <Body>
                  Step away from the screen and rediscover the world around
                  you. Take a timeout to embrace the here and now with those who
                  matter most.
                </Body>
              </Content>
              <img
                src={ChaseRiceImg1}
                alt="Chase Rice sits on the back of an airstream"
              />
            </ContentInfo>
          </ContainerUsMode>
          <SnapWrapper>
            <ContentSnap id="left">
              <ImageWrapper>
                <img
                  src={ChaseRiceImg2}
                  alt="Chase Rice playing a guitar outside by a fire pit with friends"
                />
              </ImageWrapper>
              <Content>
                <QuoteBody tabIndex={0}>
                  <img src={captionTop} alt="" id="top" />
                  “It's about disconnecting from all the stuff that can weigh
                  you down, but it's also about connecting with people and the
                  moment that's happening.”
                  <span>Chase Rice</span>
                  <img src={captionBottom} alt="" id="bottom" />
                </QuoteBody>
              </Content>
            </ContentSnap>
            <ContentSnap id="right">
              <Content>
                <Heading tabIndex={0}>LOCAL CONNECTIONS</Heading>
                <Body>
                  Dive into the heart of the communities that make each place
                  unique. Local connections spotlight the importance of engaging
                  with our neighbors, from supporting small business to
                  community events, celebrate with those around you.
                </Body>
              </Content>
              <ImageWrapper>
                <img
                  src={ChaseRiceImg3}
                  alt="Chase Rice playing shuffleboard with a friend"
                  id="sticky"
                />
              </ImageWrapper>
            </ContentSnap>
            <ContentSnap id="left">
              <ImageWrapper>
                <img
                  src={ChaseRiceImg4}
                  alt="Chase Rice singing into a microphone while playing a guitar on stage"
                />
              </ImageWrapper>
              <Content>
                <QuoteBody tabIndex={0}>
                  <img src={captionTop} alt="" id="top" />
                  “I want to experience the most normal time possible, and
                  that’s the best part about a small town– when you walk in, you
                  become a part of their life. They welcome you and make you
                  feel at home.”
                  <span>Chase Rice</span>
                  <img src={captionBottom} alt="" id="bottom" />
                </QuoteBody>
              </Content>
            </ContentSnap>
            <ContentSnap id="right">
              <Content>
                <Heading tabIndex={0}>
                  FAN <br />
                  CONNECTIONS
                </Heading>
                <Body>
                  Experience the shared passion and enthusiasm for the art we
                  love. Fan connections are more than just music; they’re about
                  shared moments and memories.
                </Body>
              </Content>
              <ImageWrapper mobileCenter={isMobile}>
                <img
                  src={ChaseRiceImg5}
                  alt="Chase Rice singing into a microphone in front of a crowd"
                />
              </ImageWrapper>
            </ContentSnap>
            <ContentSnap id="last">
              <SongImg
                src={songImg}
                alt="Chase Rice's album cover for his single 'Walk That Easy' opens in a new tab"
                onClick={() => {
                  setModalVisible(true, songUrl);
                  linkClickHandler(
                    "https://www.youtube.com/watch?v=wkxI3u5Pvlo",
                    "Artist Song",
                    "external"
                  );
                }}
                tabIndex={0}
              />
              <img
                src={patternSquareImg}
                alt="US Cellular brand pattern in blue and red"
                id="bottomPattern"
              />
            </ContentSnap>
          </SnapWrapper>
          <ContainerPadding darkTheme>
            <div style={{ maxWidth: 850 }}>
              <img src={builtForUsImg} alt="" />
              <Body>
                UScellular is built for genuine connections that bring us
                together and back together again. By emphasizing meaningful
                interactions over digital distractions, we’re bridging the gap
                between staying connected and nurturing real-world
                relationships. In other words, we’re built to make us feel a
                little more like US again.
                <br />
                <br />
                <ButtonSecondary
                  tabIndex={0}
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.uscellular.com/BuiltforUS"
                  role="link"
                  aria-label="UScellular Built for Us Link opens in a new window"
                  onClick={() =>
                    linkClickHandler(
                      "https://www.uscellular.com/BuiltForUS",
                      "UScellular Built for Us",
                      "external"
                    )
                  }
                >
                  Learn more
                </ButtonSecondary>
              </Body>
            </div>
          </ContainerPadding>
        </Container>

        <Modal open={open} setOpen={setModalVisible}>
          <Media16By9>
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  position: relative;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0px 0;
  }
`;
const SkipLink = styled.a`
  color: #ffffff00 !important;
  position: absolute;
  display: block;
  height: 20px;
  top: 0;
  bottom: 0;
  z-index: 0;
  &:visited,
  &:active {
    color: #ffffff00 !important;
  }
`;
const VideoBlockContent = styled.div`
  width: 50%;
  max-width: 550px;
  height: fit-content;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 70px;
  margin: auto;
  flex-direction: column;
  border-radius: 20px;
  background-color: #fff;
  align-items: flex-start;
  z-index: 10;
  padding: 45px 40px;
  img {
    max-width: 180px;
  }
  img#logo {
    max-width: 280px;
  }
  p {
    max-width: 500px;
    margin-top: -5px;
    margin-bottom: 35px;
  }
  h2 {
    width: 100%;
    max-width: 500px;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    position: relative;
    width: 100%;
    left: unset;
    padding: 30px;
    p {
      margin: 15px 0 30px;
    }
    img#logo {
      margin: 0 auto 30px -10px;
    }
  }
`;
const Content = styled.div`
  width: 50%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: #fff;
  align-items: center;
  &#CTA {
    align-items: flex-start;
  }
  padding: 45px 45px 65px;
  img {
    max-width: 180px;
  }
  p {
    max-width: 500px;
    margin-top: -5px;
    margin-bottom: 35px;
  }
  h2 {
    width: 100%;
    max-width: 500px;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    h2 {
      margin: 30px auto 30px 0;
    }
    img {
      margin: 10px auto 0 0;
    }
    p {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
`;
const ContentUSC = styled.div`
  width: 100%;
  position: relative;
`;
const SnapWrapper = styled.div`
  width: 100%;
  position: relative;
  /* height: 100vh; */
  /* scroll-snap-type: y mandatory !important;
  overflow-y: scroll;
  overflow: auto; */
  img#bottomPattern {
    position: absolute;
    width: 40%;
    object-fit: cover;
    margin-left: auto;
    bottom: 0;
    right: 0;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    img#bottomPattern {
      width: 90%;
      z-index: 0;
    }
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  width: 50%;
  img {
    width: 80%;
    object-fit: cover;
  }
  img#sticky {
    width: 80%;
    object-fit: cover;
    margin-left: auto;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 90%;
    margin: 0 auto 0 0;
    img#sticky {
      width: 90%;
    }
  }
  ${(props) =>
    props.mobileCenter &&
    css`
      margin: 0 auto !important;
    `}
`;

const ContentSnap = styled.div`
  width: 100%;
  background-color: #fff;
  /* scroll-snap-align: start !important;
  scroll-snap-stop: always; */
  /* height: 100vh;
  position: sticky;
  top: 0px; */
  display: flex;
  align-items: center;
  gap: 2vw;

  &#left {
    padding: 35px 0;
    justify-content: space-between;
  }
  &#right {
    padding: 35px 0;
    justify-content: space-between;
  }
  img#left,
  img#right {
    width: 50%;
    object-fit: cover;
  }
  img#rotate {
    width: 45%;
    rotate: 180deg;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column-reverse;
    height: unset;
    img {
      width: 100%;
    }
    img#rotate {
      width: 95%;
    }
    &#right {
      flex-direction: column;
      padding: 15px 0;
      justify-content: flex-start;
      ${ImageWrapper} {
        margin: 0 0 0 auto;
      }
    }
    &#last {
      height: fit-content;
      min-height: fit-content;
      justify-content: center;
      padding: 150px 0 80px;
    }
  }
`;
const SongImg = styled.img`
  object-fit: contain;
  width: 60%;
  max-width: 600px;
  margin: 50px auto 65px 15%;
  z-index: 5;
  border-radius: 25px;
  cursor: pointer;
  @media ${(props) => props.theme.maxMedia.medium} {
    margin: 20px auto;
    width: 85% !important;
  }
`;
const ContentInfo = styled.div`
  width: 90%;
  display: flex;
  margin: 100px auto 10px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  img {
    width: 45%;
    object-fit: cover;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column;
    width: 100%;
    margin: 0px auto 40px;
    img {
      width: 100%;
      padding: 20px;
    }
  }
`;
const HeroContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column-reverse;
  }
`;
const HeroContent = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  justify-content: center;
  padding: 100px 100px 100px 8vw;
  background-color: #0731a1;
  h2,
  p {
    color: #fff;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    padding: 30px;
  }
`;
const VideoContainerHero = styled.div`
  width: 90%;
  margin: 20px auto 20px;
  display: flex;
  border-radius: 25px;
  overflow: hidden;
  z-index: 10;
  /* ${({ layered }) =>
    layered &&
    css`
      margin: -4vw auto 20px;
    `} */
      @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column;
     border-radius: 25px;
  }
`;
const VideoBlock = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
  }
`;
const VideoBlockPost = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: -15px;
  position: relative;
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column;
  }
`;
const BlockContent = styled.div`
  display: flex;
  width: 55%;
  flex-direction: column;
  padding: 10px 7vw 50px;
  background-color: #0f359b;
  justify-content: center;
  h2,
  p {
    color: #fff;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 40px 25px;
    width: 100%;
    margin-top: -10px;
    button {
      margin: auto;
    }
    h2 {
      width: 60%;
    }
  }
`;
const HeroRegContent = styled.div`
  width: 40%;
  max-width: 550px;
  position: absolute;
  top: 18%;
  left: 5vw;
  z-index: 10;
  padding: 35px;
  border-radius: 6px;
  background-color: rgba(256, 256, 256, 0.8);
  h1 {
    color: #0731a1 !important;
    margin: 0;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    top: unset;
    bottom: 10%;
    width: 80%;
    background-color: transparent;
    h1 {
      color: #fff !important;
    }
    button,
    button:hover {
      background-color: #fff;
      color: #0731a1 !important;
    }
  }
`;
const HeroBackground = styled.img`
  min-height: 500px;
  max-height: 770px;
  width: 100%;
  object-fit: cover;
  z-index: 2;
  @media (max-width: 550px) {
    min-height: unset;
    max-height: 100vh;
    object-position: top;
  }
`;
const HeroPostBackground = styled.div`
  display: flex;
  width: 55%;
  z-index: 2;
  img {
    width: 100%;
    object-fit: cover;
    object-position: top center;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
  }
`;
const PartPatternImg = styled.img`
  /* min-height: 500px;
  max-height: 770px; */
  display: block;
  width: 100%;
  object-fit: cover;
  z-index: 2;
  margin-top: auto;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 120px;
  align-items: stretch;
  & > div#summerfest {
    margin-top: 100px;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column-reverse;
    gap: 40px;
    & > div#summerfest {
      margin-top: 40px;
    }
  }
`;
const FormWrapper = styled.div`
  width: 35%;
  display: flex;
  margin-left: auto;
  flex-direction: column;
  & > p {
    margin: -20px 0 30px;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    width: 100%;
    padding: 20px;
    & > p {
      margin: 20px 0px 0;
    }
  }
`;

const ContainerPadding = styled.div`
  width: 100%;
  background-color: #0731a1;
  padding: 30px;
  & > div {
    margin: auto;
    width: 90%;
    display: flex;
    gap: 40px;
    align-items: center;
    p {
      color: #fff;
      margin-top: 10px;
    }
    img {
      max-width: 250px;
      object-fit: contain;
      margin-bottom: auto;
    }
  }
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      background-color: #1b2a55;
    `}
  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 35px 20px;
    & > div {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 10px;
    }
  }
`;
const ContainerScroll = styled.div`
  flex-direction: column;
  width: 100%;
  background-color: #0731a1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  margin-top: -2px;

  h3 {
    color: #fff;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column-reverse;
    text-align: center;
  }
`;
const ContainerUsMode = styled.div`
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top 0 right 40%;
  padding: 60px 70px 100px;
  position: relative;
  img#rotate {
    width: 45%;
    rotate: 180deg;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media ${(props) => props.theme.maxMedia.medium} {
    background: #fff;
    padding: 0;
    & > img {
      object-fit: cover;
      width: 100%;
      margin: 25px 0 -7px 0;
    }
    & > img#mobileBgImg {
      object-fit: cover;
      width: 100%;
      height: 400px;
      object-position: top right;
    }
    img#rotate {
      width: 95%;
      rotate: 180deg;
      position: relative;
      object-fit: cover;
      margin: 0;
      top: 0;
      left: 0;
    }
  }
`;
const VideoContent = styled(VideoPlayer)`
  video {
    object-fit: cover;
    object-position: top left;
  }
  @media (max-width: 950px) {
    video {
      object-position: top left;
    }
  }
  @media (max-width: 550px) {
    video {
      object-fit: contain;
      //    object-position: top center;
    }
  }
`;
const LogoBox = styled.div`
  padding: 20px 0 15px;
  display: flex;
  justify-content: center;
`;

const Link = styled.a`
  width: 100%;

  @media (max-width: 950px) {
    max-width: 300px;
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  display: flex;

  @media (max-width: 950px) {
    margin: 0 auto;
  }
`;

const MainHeading = styled.h1`
  font-size: min(5.6vw, 64px);
  font-family: "BrandonBld" !important;
  color: #fff;
  text-transform: uppercase;

  & > span {
    display: block;
    font-size: 20px;
    padding-top: 10px;
  }

  @media ${(props) => props.theme.maxMedia.medium} {
    font-size: 40px;
    text-shadow: 0px 0px 5px #000;

    & > span {
      font-size: 1.2rem;
    }
  }
`;

const Heading = styled.h2`
  font-size: min(3.8vw, 48px);
  text-transform: uppercase;
  font-family: "BrandonBld";
  @media ${(props) => props.theme.maxMedia.medium} {
    font-size: 36px;
    margin: 0;
  }
`;
const SubHeading = styled.h3`
  font-size: 24px;
`;
const QuoteBody = styled.p`
  font-size: min(28px, 1.8vw);
  font-family: Georgia, "Times New Roman", Times, serif;
  text-align: center;
  border: 1px solid #82dcff;
  border-radius: 20px;
  font-weight: 600;
  color: #0731a1;
  line-height: 170%;
  padding: 55px 40px;
  display: block;
  position: relative;
  margin-left: -7vw;
  span {
    display: block;
    text-align: center;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 20px;
  }
  img#top {
    position: absolute;
    top: -25px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  img#bottom {
    position: absolute;
    bottom: -15px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  @media ${(props) => props.theme.maxMedia.medium} {
    font-size: max(2.3vw, 16px) !important;

    padding: 30px;
    span {
      font-size: max(2vw, 14px) !important;
    }
  }
`;
const Body = styled.p`
  font-size: 20px;
  font-family: "BrandonReg";

  span.headline {
    font-size: 30px;
    color: #0731a1;
    display: inline-block;
    margin: 25px 0 10px;
    font-family: "BrandonMed";
  }
  span.super {
    font-size: 12px;
    display: inline-block;
    margin: -10px 0 0 0;
    position: relative;
    top: -5px;
  }

  /* @media (max-width: 950px) {
    font-size: 0.9rem;
  } */
  ${({ float }) =>
    float &&
    css`
      font-size: min(1.7vw, 20px);
    `}
    @media ${(props) => props.theme.maxMedia.medium}{
      font-size: 20px;
    }
`;
const HeadingPostCampaign = styled(Heading)`
  // padding-right: 40px;
`;
const BodyPostCampaign = styled(Body)`
  @media (max-width: 950px) {
    font-size: 0.9rem;
  }
`;

const SubBody = styled.p`
  font-size: 0.65rem;

  @media (max-width: 950px) {
    font-size: 0.9rem;
  }
`;

const topBorder = `
  border-top: 1px solid #707070;
  padding-top: 24px;
  margin-top: 24px;

`;

const PoweredBy = styled.div`
  ${(props) => props.postCampaign && "border-top: 0px solid #707070;"}
`;

const BrandSecondaryCTA = styled.div`
  font-size: 0.8rem;

  a {
    @media (max-width: 950px) {
      align-self: center;
      display: block;
      width: 50%;
      margin: 0 auto;
      padding: 10px 0;
      font-size: 0.9rem;
      margin-top: 40px;
    }
  }
`;

const ArtistContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  background-color: #fff;
`;

const ArtistContentTop = styled.div`
  display: flex;
`;

const BrandImage = styled.div`
  flex: 1;
  background-image: url(${(props) => props.mobile});
  background-position: center;
  background-size: cover;
  padding-top: 56.25%;

  @media ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktop});
    background-position: center;
  }
`;

const HeroVideoMobile = styled.div`
  @media ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const HeroVideoDesktop = styled.div`
  display: none;

  @media ${(props) => props.theme.maxMedia.medium} {
    display: flex;
    width: 66%;
  }
`;

const MusicVideo = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media ${(props) => props.theme.maxMedia.medium} {
    flex: 1 1 0%;
    padding: 0;
  }
`;

const MobileImage = styled.img`
  @media ${(props) => props.theme.maxMedia.medium} {
    display: none;
  }
`;

const DesktopImage = styled.img`
  display: none;

  @media ${(props) => props.theme.maxMedia.medium} {
    display: block;
    width: 100%;
  }
`;

const BrandCTA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    position: absolute;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:hover > img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  @media ${(props) => props.theme.maxMedia.medium} {
    & > img {
      width: 100%;
    }
  }
`;

const BrandCTAHeading = styled.h3`
  color: #ffffff;
  padding: 0 40px;
  z-index: 1;
  font-size: 1.7rem;
`;

const Label = styled.div`
  background-color: rgba(0, 9, 91, 0.9);
  color: #ffffff;
  padding: 10px 35px;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  text-decoration: none;
  font-family: "BrandonReg";
  font-size: 13px;
  @media ${(props) => props.theme.maxMedia.medium} {
    font-size: 13px;
  }
`;
const Pattern = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;
`;
const PatternMobile = styled.img`
  width: 100%;
  margin-top: -50px;
  /* position: absolute;
  bottom: 8%;
  left: 0;
  z-index: 1;
  ${(props) =>
    !props.isRegistered &&
    css`
      bottom: 7%;
    `} */
`;
