import styled from "styled-components";
import { GridColumn } from "notes";
import { Radio } from "./RadioNotes";
import { RadioTitle } from "notes/lib/Forms/Radio";
import { FieldMessage } from "Components";

export const RadioField = ({ onChange, value, field, error = {} }) => {
  const handleRadio = (value) => {
    console.log("radio", value);
    onChange({ [field.name]: value });
  };
  return (
    <>
      <StyledGrid aria-required={field.required} id={field.name}>
        {field.options?.map(({ id, title, subtitle }) => {
          return (
            <InlineRadio
              key={id}
              id={id}
              checked={value === title}
              onChange={() => handleRadio(title)}
              title={title}
              aria-label={`checkbox with value ${title}`}
              role="button"
              name={field.label}
            ></InlineRadio>
          );
        })}
      </StyledGrid>
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};

const InlineRadio = styled(Radio)`
  margin-top: 0;
  margin-bottom: 0;
  span {
    font-size: 12px;
    padding-right: 20px;
  }
  svg {
    border: 1px solid #000000b6;
    border-radius: 3px;
    padding: 0px;
  }
  svg.lowIyQ {
    circle#inner {
      transform: scale(3);
    }
  }
  #outer {
    stroke: #fff;
    fill: #fff;
  }
  #inner {
    fill: #2c3cf3;
    r: 15 !important;
  }
`;
const StyledGrid = styled(GridColumn)`
  display: flex;
  flex-direction: row;
`;
const StyledRadio = styled(RadioTitle).attrs({ tabIndex: 0 })``;
