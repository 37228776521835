import { FieldMessage, Input } from "Components";

export const TextField = ({ onChange, value, field, error = {}, ...props }) => {
  const handleText = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      <Input
        placeholder={field.placeholder}
        onChange={(value) => handleText(value)}
        value={value}
        required={field.required}
        error={!!error.status}
        success={!error.status && value}
        name={field.label}
        id={field.name}
        {...props}
      />
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};
